<template>
  <div id="purchase-add">
    <q-dialog v-model="isMemberDialogOpen" full-height >
      <SupplierList showIcon isDialog @clicked="selectSupplier" />
    </q-dialog>
    <q-dialog v-model="isProductDialogOpen" full-height >
      <ProductList showIcon isDialog @clicked="selectProduct" />
    </q-dialog>
    <div v-if="purchaseData.invalid" class="bg-white rounded-lg shadow p-2">
      {{$t('rule.not_available')}}
    </div>
    <q-form v-else @submit="saveAdd" class="d-flex flex-column gap-3">
      <div class="d-flex flex-column gap-2 card overflow-hidden">
        <!-- Details -->
        <h5 class="text-primary bg-light-secondary px-2 py-1"><fa-icon :icon="['fad','brackets-curly']" fixed-width class="mr-1"/>{{$t('form.details')|capitalize}}</h5>
        <div class="d-flex gap-2 flex-column px-2">
          <div class="d-flex gap-2 flex-wrap flex-column flex-md-row">
            <!-- orderNo -->
            <q-input type="text" dense outlined hide-bottom-space clearable v-model="purchaseData.orderNo" class="mb-0 flex-even" 
              :label="$t('form.order_number')|capitalize" :loading="loading" autocomplete="off"
              :rules="[ val=>Boolean(val)||$t('rule.required'), val=> val.length<=50 || $t('rule.too_long')]">
              <template #prepend><fa-icon :icon="['fad','barcode-read']" fixed-width class="text-danger mr-25"/></template>
            </q-input>
            <!-- trackNo -->
            <q-input type="text" dense outlined hide-bottom-space clearable v-model="purchaseData.trackNo" class="mb-0 flex-even" 
              :label="$t('form.track_number')|capitalize" :loading="loading" autocomplete="off"
              :rules="[ val=>!val||val.length<=50 || $t('rule.too_long')]">
              <template #prepend><fa-icon :icon="['fad','satellite-dish']" fixed-width class="text-primary mr-25"/></template>
            </q-input>
            <!-- Arrived at -->
            <q-input outlined dense hide-bottom-space v-model="purchaseData.orderedAt" mask="date" :rules="[val=>Boolean(val)||$t('rule.required'),'date']" class="mb-0 flex-even">
              <template #prepend><fa-icon :icon="['fad','calendar-minus']" fixed-width class="text-danger mr-1"/></template>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="purchaseData.orderedAt" style="height:376px">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="d-flex gap-2 flex-wrap flex-column flex-md-row">
            <!-- Details -->
            <q-input type="text" dense outlined hide-bottom-space clearable autogrow v-model="purchaseData.details" class="mb-0 rounded-lg flex-even" 
              :label="$t('form.details')|capitalize" :loading="loading" autocomplete="off"
              :rules="[ val=>!val||val.length<=200 || $t('rule.too_long')]">
              <template #prepend><fa-icon :icon="['fad','info']" fixed-width class="text-primary mr-25"/></template>
            </q-input>
            <!-- tags -->
            <q-select v-model="purchaseData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input dense 
              :placeholder="$t('message.enter_add_new')" class="flex-even">
              <template #selected-item="scope">
                <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
              </template>
              <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
            </q-select>
          </div>
        </div>
        

<!-- 供应商 -->
        <h5 class="text-primary bg-light-secondary px-2 py-75 d-flex gap-2 align-items-center">
          <div>
            <fa-icon :icon="['fad','building']" fixed-width class="mr-1"/>
            {{$t('finance.role.supplier')|capitalize}}
          </div>
        </h5>
        <!-- selected supplier -->
        <div class="d-flex px-2">
          <q-field ref="typeRef" hide-bottom-space dense :value="purchaseData.member" borderless :rules="[val=>Boolean(val) || $t('rule.required')]">
            <q-btn v-if="!purchaseData.member" push @click.stop="isMemberDialogOpen=true" color="deep-orange">{{$t('member.choose_supplier')}}</q-btn>
          </q-field>
          <div v-if="purchaseData.member" @click.stop="isMemberDialogOpen=true" class="light-primary d-flex gap-2 border-primary rounded-lg px-1 py-50 bg-light-primary align-items-center">
            <BAvatar :src="fileUrl(purchaseData.avatar,'member')" variant="light-primary" size="4rem"><fa-icon icon="building" size="2x"/></BAvatar>
            <div class="d-flex flex-column">
              <div class="h5">{{purchaseData.supplier.corpName}}</div>
              <small>{{purchaseData.supplier.uen}}</small>
              <small>{{purchaseData.supplier.address}}</small>
            </div>
          </div>
        </div>

<!-- 项目 -->
        <h5 class="text-primary bg-light-secondary px-2 py-75 d-flex justify-content-between align-items-center">
          <span><fa-icon :icon="['fad','list']" fixed-width class="mr-1"/>{{$t('form.items')|capitalize}}</span>
          <span class="d-flex gap-2 align-items-center">
            <BButton pill @click.stop="addItem" variant="primary" class="mr-1 shadow-sm" size="sm">
              <fa-icon icon="plus" class="mr-50"/><span class="text-capitalize">{{$t('action.add')}}</span>
            </BButton>
            <!-- DR / CR -->
            <span class="text-primary"><q-tooltip label="Scale Menu" anchor="top middle" :offset="[0, 30]">{{$t('finance.debit') | capitalize}}</q-tooltip>{{total.dr | price}}</span>
            <q-separator vertical/>
            <span class="text-warning"><q-tooltip label="Scale Menu" anchor="top middle" :offset="[0, 30]">{{$t('finance.credit') | capitalize}}</q-tooltip>{{total.cr | price}}</span>
          </span>
        </h5>
        <transition-group name="items-transition" class="d-flex gap-2 flex-column">
        <div class="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-start gap-2 p-75 mx-1 mx-sm-2 rounded-lg" v-for="(item, i) in purchaseData.items" :key="item.index" :class="item.isInventory?'border-primary':'border-danger'"><!-- 一组 -->
          <div class="d-flex flex-even justify-content-between align-items-start gap-2">
            <!-- Inventory product -->
            <q-chip v-if="item.product" @click.stop="openProductDialog(i)" class="py-0 m-0 pr-0" size="lg" :disable="item.isJournaled">
              <q-avatar :color="item.isInventory?'primary':'info'" text-color="white"><fa-icon :icon="item.isInventory?'box':'wrench'" /></q-avatar>
              {{item.name}}
              <q-btn rounded flat color="danger" @click.stop="resetItem(i)" :disable="item.isJournaled"><fa-icon icon="times"/></q-btn>
            </q-chip>

            <!-- name & account -->
            <q-input v-if="!item.product" type="text" outlined dense hide-bottom-space v-model="item.name" :disable="item.isJournaled" :label="$t('form.name')|capitalize"
              class="flex-even flex-even price m-0" :rules="[ val=>Boolean(val)||$t('rule.required')]">
              <template #prepend>
                <q-btn push dense @click.stop="openProductDialog(i)" color="deep-orange" class="ml-n25 mr-50" ><fa-icon icon="boxes" /></q-btn>
                <!-- <fa-icon :icon="['fad','wrench']" fixed-width class="text-primary mr-50"/> -->
                <q-select outlined square dense hide-bottom-space :label="$t('finance.account')|capitalize" v-model="item.account" class="bg-light-primary" popup-content-class="mt-1"
                  :options="expenseOptions" option-value="_id" option-label="name" map-options emit-value style="min-width: 8rem" :rules="[ val=>Boolean(val)||'']">
                </q-select>
              </template>
              <template #append></template>
            </q-input>

            <!-- Button -->
            <q-btn-group rounded class="d-flex d-lg-none flex-nowrap" :disable="item.isJournaled">
              <q-btn color="primary" @click="copyItem(i)"><fa-icon :icon="['fas','copy']" /></q-btn>
              <q-btn color="danger" @click="removeItem(i)"><fa-icon :icon="['fas','trash-alt']" /></q-btn>
            </q-btn-group>
          </div>

          <!-- price -->
          <div class="flex-even flex-size-2 d-flex">
            <q-input type="number" outlined dense hide-bottom-space :disable="item.isJournaled" v-model="item.unitPrice" :label="$t('form.unit_price')|capitalize" step="0.01" @change="calculateTotalPrice(i)"
              class="flex-even m-0 left" :rules="[val=>Boolean(val) || $t('rule.required'), val=>val>=0 || $t('rule.positive')]">
              <template #prepend><small class="text-bold text-danger mr-25 d-none d-sm-inline">{{item.currency}}</small></template>
            </q-input>
            <q-input type="number" outlined square dense :disable="item.isJournaled" hide-bottom-space v-model="item.quantity" :label="$t('form.quantity')|capitalize" @change="calculateTotalPrice(i)"
              class="flex-even m-0" :rules="[val=> Boolean(val) && item.quantity!=='0' || $t('rule.required')]">
              <template #prepend>
                <fa-icon :icon="['fad','times']" fixed-width class="text-danger mr-25 d-none d-sm-inline"/>
              </template>
            </q-input>
            <q-input type="number" disable filled dense hide-bottom-space clearable :disable="item.isJournaled" v-model="item.amount" :label="$t('form.total')|capitalize" step="0.01" class="flex-even m-0 right">
              <template #prepend><small class="text-bold text-danger mr-25 d-none d-sm-inline">{{item.currency}}</small></template>
            </q-input>
          </div>

          <!-- description -->
          <q-input outlined dense hide-bottom-space v-model="item.description" :disable="item.isJournaled" :label="$t('form.description')|capitalize" class="flex-even m-0">
            <template #prepend><fa-icon :icon="['fad','align-left']" fixed-width class="text-primary mr-25"/></template>
          </q-input>

          <!-- Button -->
          <q-btn-group rounded class="d-none d-lg-flex flex-nowrap">
            <q-btn color="primary" @click="copyItem(i)"><fa-icon :icon="['fas','copy']" size="1x" /></q-btn>
            <q-btn color="danger" :disable="item.isJournaled" @click="removeItem(i)"><fa-icon :icon="['fas','trash-alt']" size="1x" /></q-btn>
          </q-btn-group>
        </div>
        </transition-group>

        <!-- 图片上传 -->
        <div class="mx-1 mx-sm-2">
          <FileUpload automatic multiple :action="`${$global.server_url}/purchase/${purchaseData._id?purchaseData._id+'/':''}file`" fileName="files" class="mx-2" model="purchase"
          :files="purchaseData._id?purchaseData.files:[]" @on-success="successUpload" @on-error="failUpload" @on-delete="deleteUpload" />
        </div>
        
        <div class="m-2 d-flex justify-content-between">
          <q-btn push color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push color="danger" @click="$router.go(-1)" :label="capitalize($t('action.cancel'))"/>
        </div>
      </div>

    </q-form>
  </div>
</template>

<script>
import axios from '@axios'
import $global from '@/globalVariables'
import { ref }  from '@vue/composition-api'
import { BAvatar, BButton } from 'bootstrap-vue'
import { price, capitalize, toNumber, fileUrl } from '@core/utils/filter'
import { Toast } from '@core/utils/other'
import router from '@/router'
import usePurchase from './usePurchase'

import SupplierList from '../../member/supplier/SupplierList'
import ProductList from '../../supply-chain/product/ProductList'

import FileUpload from '@core/components/file-upload/FileUpload'
import 'vuesax/dist/vuesax.css'

export default {
  name: 'purchase-add',
  components: { 
    BAvatar, BButton,
    FileUpload,
    SupplierList,
    ProductList
  },
  filters: {
    price, capitalize
  },
  setup() {
    const isMemberDialogOpen = ref(false)
    const isProductDialogOpen = ref(false)
    const {
      loading, tt,
      total,
      // Edit
      purchaseData,
      fetchPurchase,

      // Upload
      successUpload,
      deleteUpload,
      failUpload,
      saveAdd 
    } = usePurchase()

    let currentIndex = 1;

    if(router.currentRoute && router.currentRoute.params && router.currentRoute.params.id) fetchPurchase(router.currentRoute.params.id, function(){
      // 生成 items index
      if(purchaseData.value && purchaseData.value.items && purchaseData.value.items.length>0) {
        currentIndex = 1;
        purchaseData.value.items.forEach(item => {
          currentIndex++
          item.index = currentIndex
        })
      }
    })

// Member dialog
    const selectSupplier = val=>{
      if(val) {
        purchaseData.value.supplier = Object.keys(val).filter(
          key=>['corpName','uen','address'].includes(key)).reduce( (res,key)=>(res[key] = val[key],res),{}) //显示supplier
        purchaseData.value.member = val._id //保存member id
        isMemberDialogOpen.value = false
      } else {
        purchaseData.value.member = ''
        isMemberDialogOpen.value = false
      }
    }
   

// Product dialog
    const currentProductDialogIndex = ref(null) 
    const selectProduct = val=>{
      if(val) {
        Object.assign(
          purchaseData.value.items[currentProductDialogIndex.value], 
          Object.keys(val).filter(key=>['name','sku','account','isInventory'].includes(key)).reduce( (res,key)=>(res[key] = val[key],res),{}) //保存在运单
        )
        purchaseData.value.items[currentProductDialogIndex.value]['product'] = val._id //保存 product id
        isProductDialogOpen.value = false
      } else {
        resetItem(currentProductDialogIndex.value)
        isProductDialogOpen.value = false
      }
    }
    const openProductDialog = key=>{
      isProductDialogOpen.value = true
      currentProductDialogIndex.value = key
    }

// Items
    const addItem = function() {
      currentIndex++;
      purchaseData.value.items.push({
        index: currentIndex, currency:$global.default_currency,
        isInventory: false, account:'',
        unitPrice:0, quantity:1, amount:0, drcr: 'dr',
      })
    }
    const copyItem = function(i) {
      currentIndex++;
      const newItem = JSON.parse(JSON.stringify(purchaseData.value.items[i]))
      delete newItem._id
      delete newItem.isApproved
      delete newItem.isJournaled
      purchaseData.value.items.push({...newItem, index:currentIndex})
    }
    const removeItem = (i) => {
      if(purchaseData.value.items.length<=1) return Toast.fire({icon:'error', title: tt.atLeastOne})
      purchaseData.value.items.splice(i, 1)
    }
    const resetItem = (i)=>{
      const item = purchaseData.value.items[i]
      item.isInventory = false
      item.account=''
      item.name=''
      item.product=null
      item.drcr='dr'
      delete item.sku
      delete item.variant
    }

    const expenseOptions = ref([])
    axios.get(`/account/list/purchase-expense`).then(response => {
      if (response.data.expenses) expenseOptions.value = response.data.expenses
    }).catch(e=>console.log(e))
    const calculateTotalPrice = (i) => {
      purchaseData.value.items[i].quantity = toNumber(purchaseData.value.items[i].quantity)
      purchaseData.value.items[i].unitPrice = toNumber( purchaseData.value.items[i].unitPrice)
      purchaseData.value.items[i].amount = toNumber( purchaseData.value.items[i].quantity * purchaseData.value.items[i].unitPrice)
    }
    
    return {
      loading, capitalize, toNumber, isMemberDialogOpen, isProductDialogOpen, fileUrl, tt,
      selectSupplier, selectProduct,
      currentProductDialogIndex,
      openProductDialog,

      addItem,
      copyItem,
      removeItem,
      resetItem,
      total,
      calculateTotalPrice,

      expenseOptions,

      purchaseData,
      fetchPurchase,

      // Upload
      successUpload,
      deleteUpload,
      failUpload,

      saveAdd
    }
  },
}
</script>

<style lang="scss">

#purchase-add {
  .q-item {
    padding: 0
  }
  .phone {
    .q-field__control-container {
      flex-wrap: nowrap;
      width: 60px
    }
  }

  .items-transition-enter-active {
    transition: all .3s ease;
  }
  .items-transition-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .items-transition-enter, .items-transition-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .q-field__prepend.row {
    flex-wrap:nowrap;
    label {
      margin-bottom: 0;
    }
  }

  .q-chip {
    border-radius:10rem;
    .q-avatar {
      border-radius:10rem;
    }
  }

  .q-btn-group {
    margin-top:2px;
  }

  .left {
    .q-field__control{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: transparent;
    }
  }
  .right {
    .q-field__control{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: transparent;
    }
  }
}
</style>