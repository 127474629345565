import { render, staticRenderFns } from "./PurchaseAdd.vue?vue&type=template&id=17ee1f90&"
import script from "./PurchaseAdd.vue?vue&type=script&lang=js&"
export * from "./PurchaseAdd.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseAdd.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QField from 'quasar/src/components/field/QField.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QItem from 'quasar/src/components/item/QItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QForm,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QChip,QField,QTooltip,QSeparator,QAvatar,QBtnGroup,QItem});qInstall(component, 'directives', {ClosePopup});
